.mastcontainer-map {
  height: 100vh;
}

.btn-map-setting {
  position: absolute;
  z-index: 2;
  right: 1rem;
  top: 5rem;
}

.modal-title {
  font-weight: 900;
}

.settings-modal {
  position: absolute;
  z-index: 0;
  min-height: 5rem;
  min-width: 25rem;
  top: 4rem;
  right: 0;
  opacity: 0;
  padding: 1rem;
  background-color: #fff;
  transition: 0.4s;
  margin: 0.75rem;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
}

.settings-check {
  display: flex !important;
  align-items: center;
  .form-check-input {
    margin-top: 0;
    height: 1.5em;
    width: 2.5em !important;
  }
  .form-check-input:hover {
    cursor: pointer;
  }
  .form-check-label {
    margin-bottom: -2px;
  }
}

.settings-modal.active {
  z-index: 5;
  opacity: 1;
}

.settings-close-button {
  color: #333 !important;
  padding: 0 0.25rem !important;
  height: 1rem;
  font-size: 0.7rem !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.settings-close-button:focus {
  outline: none !important;
}

.custom-control {
  padding-left: 1.625rem !important;
}
.custom-control-label::after,
.custom-control-label::before {
  left: -1.625rem !important;
  border-radius: 50%;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2em;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3E%3C/svg%3E") !important;
  background-position: 0;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2386b7fe'/%3E%3C/svg%3E");
}

.form-check-input:checked {
  background-color: #0088ce;
  border-color: #0088ce;
}

.form-switch .form-check-input:checked {
  background-position: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E") !important;
}

.form-switch .form-check-label {
  margin-left: 1rem;
}

.mapboxgl-popup {
  max-width: 50rem;
}

.mapboxgl-hover-custom-popup .mapboxgl-popup-content {
  padding: 0;
  border-radius: 0.4375rem !important;

  max-height: 35rem;
  overflow: auto;
}
/* .mapboxgl-hover-custom-popup .mapboxgl-popup-close-button {
  display: none;
} */
.mapboxgl-popup-tip {
  border: none !important;
}
.mapboxgl-popup-title {
  padding: 5px 30px 5px 10px;
  font-size: 0.8em;
  /* background-color: var(--cyan); */
  color: var(--white);
  border-radius: 0.4375rem 0.4375rem 0 0;
  position: fixed;
  width: 100%;
}
.mapboxgl-popup-container {
  padding: 50px 5px 10px;
  font-size: 0.8em;
}
.labelvalue {
  font-size: 0.8em;
}
.labelvalue-label {
  font-size: 0.8em;
  font-weight: bold;
  margin-right: 1em;
}

.mapboxgl-popup-close-button {
  color: white;
  position: fixed !important;
  z-index: 3;
  right: 2px !important;
  top: 2px !important;
}
